<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Add Coin Order
        <div class="card-header-actions">
          <a class="card-header-action" href="dinar-orders" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
      <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
              <div class="sk-child sk-bounce1"></div>
              <div class="sk-child sk-bounce2"></div>
              <div class="sk-child sk-bounce3"></div>
          </div>
      </div>
      <b-row v-if="this.sold !== ''">
        <b-col sm="12">
          <div>
            <b-alert show variant="danger" dismissible>
              - {{ this.sold }}
            </b-alert>
          </div>
        </b-col>
      </b-row>
        <b-row>
          <b-col>
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="postOrder" novalidate action="https://vuejs.org/">
              <b-row class="text-center">
                <b-col v-for="(data , index) in products" :key="index">
                  <b-img thumbnail fluid :src="data.image_url" class="products" width="250px" height="250px"
                  ></b-img>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="productSelected"
                      @change="retrieveProduct(data, $event)"
                    >
                    {{ data.name }}
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group
                    :label="'Quantity ' + data.name"
                  >
                    <b-form-input
                      type="number"
                      @input="updateQty(index, $event)"
                      v-model="qtyProduct[index]"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group id="receiverInputGroup4"
                            label="Nama Penerima"
                            label-for="receiver">
                <b-form-input id="receiver"
                              type="text"
                              v-model.trim="$v.form.receiver.$model"
                              :state="chkState('receiver')"
                              aria-describedby="receiverFeedback"
                              placeholder="Nama Penerima"
                              autocomplete='receiver' />
                <b-form-invalid-feedback id="receiverFeedback" v-for="(error , index) in errors.form.receiver" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="emailInputGroup4"
                            label="Email"
                            label-for="email">
                <b-form-input id="email"
                              type="email"
                              v-model.trim="$v.form.email.$model"
                              :state="chkState('email')"
                              aria-describedby="emailFeedback"
                              placeholder="Email"
                              autocomplete='email' />
                <b-form-invalid-feedback id="emailFeedback" >
                  <span v-if="!$v.form.email.required">- Email User tidak boleh kosong!</span>
                  <span v-if="!$v.form.email.email">- Email User tidak valid!</span>
                  <span v-if="!$v.form.email.isUnique">- Email User tidak terdaftar!</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="phoneInputGroup4"
                            label="Nomor Telepon Penerima"
                            label-for="phone">
                <b-form-input id="phone"
                              type="text"
                              v-model.trim="form.phone"
                              aria-describedby="phoneFeedback"
                              placeholder="Nomor Telepon Penerima"
                              autocomplete='phone' />
              </b-form-group>
              <b-form-group id="rContentInputGroup4"
                            label="Status Pembayaran (Jika sudah melakukan pembayaran pilih sudah dibayar)"
                            label-for="rContent">
                <b-form-select id="status_pay"
                              :plain="true"
                              v-model.lazy.trim="$v.form.status_pay.$model"
                              autocomplete='given-name'
                              autofocus
                              :options="[{value: '', text:'Pilih Status Pembayaran'},
                                                {value: 'Dibatalkan', text: 'Dibatalkan'},
                                                {value: 'Menunggu Pembayaran', text: 'Menunggu Pembayaran'},
                                                {value: 'Sudah Dibayar', text: 'Sudah Dibayar'}]">
                  </b-form-select>
              </b-form-group>
              <b-form-group id="rContentInputGroup4"
                            label="Metode Pembelian"
                            label-for="rContent">
                <b-form-select id="buy_method"
                              :plain="true"
                              v-model.lazy.trim="$v.form.buy_method.$model"
                              :state="chkState('buy_method')"
                              aria-describedby="paymentMethodFeedback"
                              autocomplete='given-name'
                              autofocus
                              :options="[{value: '', text:'Pilih Status Pembayaran'},
                                                {value: 'direct', text: 'Beli'},
                                                {value: 'cetak', text: 'Cetak'}]">
                </b-form-select>
                <b-form-invalid-feedback id="paymentMethodFeedback" v-for="(error , index) in errors.form.method" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="addressInputGroup4"
                            label="Alamat Pengiriman"
                            label-for="address">
                <b-form-input id="address"
                              type="text"
                              v-model.trim="$v.form.address.$model"
                              :state="chkState('address')"
                              aria-describedby="shippingAddressFeedback"
                              placeholder="Alamat"
                              autocomplete='address' />
                <b-form-invalid-feedback id="shippingAddressFeedback" v-for="(error , index) in errors.form.shipping_address" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="postInputGroup4"
                            label="Kode Pos"
                            label-for="post_code">
                <b-form-input id="post_code"
                              type="text"
                              v-model.trim="$v.form.post_code.$model"
                              :state="chkState('post_code')"
                              aria-describedby="postalCodeFeedback"
                              placeholder="Postalcode"
                              autocomplete='post_code' />
                <b-form-invalid-feedback id="postalCodeFeedback" v-for="(error , index) in errors.form.postal_code" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label="Provinsi"
              >
                <v-select v-model="province" :options="provincies" @input="doSearchProvince" :on-change="getCities" placeholder="Select option"></v-select>
              </b-form-group>
              <b-form-group
                label="Kota"
              >
                <v-select v-model="city" :options="cities" @input="doSearchCity" placeholder="Select option"></v-select>
              </b-form-group>
              <b-form-group id="provider"
                            label="Metode Pengiriman"
                            label-for="providers">
                <b-form-select id="providers"
                              :plain="true"
                              v-model="form.provider"
                              autocomplete='given-name'
                              autofocus
                              :options="[{value: 'rpx', text: 'RPX'},
                                                {value: 'paxel', text: 'PAXEL'}]">
                </b-form-select>
              </b-form-group>
              <b-button variant="success" type="button" class="m-3" @click="getShippingRates">Caculate Shipping Fee</b-button>
              <b-form-group id="order_payInputGroup4"
                            label="Harga Pengiriman"
                            label-for="order_pay">
                <b-form-input id="order_pay"
                              type="text"
                              v-model.trim="$v.form.order_pay.$model"
                              :state="chkState('order_pay')"
                              aria-describedby="shippingFeeFeedback"
                              placeholder="Harga Pengiriman"
                              autocomplete='address' />
                <b-form-invalid-feedback id="shippingFeeFeedback">
                  <span v-if="!$v.form.order_pay.numeric">- Biaya pengiriman harus berupa angka dan tidak boleh kurang dari 0! {{ "\n"}}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="rContentInputGroup4"
                            label="Metode Pengiriman"
                            label-for="rContent">
                <b-form-select id="shipping_method"
                              :plain="true"
                              v-model.lazy.trim="$v.form.shipping_method.$model"
                              :state="chkState('shipping_method')"
                              aria-describedby="shippingMethodFeedback"
                              autocomplete='given-name'
                              autofocus
                              :options="[{value: '', text:'Pilih Metode Pengiriman'},
                                                {value: 'Delivery', text: 'Delivery'},
                                                {value: 'COD', text: 'COD'}]">
                </b-form-select>
                <b-form-invalid-feedback id="shippingMethodFeedback" v-for="(error , index) in errors.form.shipping_method" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="paymentInputGroup1"
                            label="Payment Method (Tidak perlu dipilih jika pembelian menggunakan celengan )"
                            label-for="payment">
                <b-form-select id="payment"
                              :plain="true"
                              v-model="payment"
                              autocomplete='given-name'
                              autofocus >
                              <option value="">Pilih payment</option>
                              <option v-for="(payment, index) in payments" :key="index" v-bind:value="payment.id">- {{ payment.payment_name }}</option>
                </b-form-select>
              </b-form-group>
              <b-form-group id="rContentInputGroup4"
                            label="Status"
                            label-for="rContent">
                <b-form-select id="status"
                              :plain="true"
                              v-model.lazy.trim="$v.form.status.$model"
                              :state="chkState('status')"
                              aria-describedby="statusFeedback"
                              autocomplete='given-name'
                              autofocus
                              :options="[{value: '', text:'Pilih Status Pesanan'},
                                                {value: 'Dikirim', text: 'Dikirim'},
                                                {value: 'Dibatalkan', text: 'Dibatalkan'},
                                                {value: 'Menunggu Pembayaran', text: 'Menunggu Pembayaran'},
                                                {value: 'Sedang Diproses', text: 'Sedang Diproses'}]">
                </b-form-select>
                <b-form-invalid-feedback id="statusFeedback" v-for="(error , index) in errors.form.status" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="emailInputGroup4"
                            label="Nomor Resi"
                            label-for="n_resi">
                <b-form-input id="n_resi"
                              type="text"
                              v-model.trim="form.n_resi"
                              aria-describedby="trackingFeedback"
                              placeholder="Nomor Resi"
                              autocomplete='email' />
              </b-form-group>
              <b-form-group id="lastNameInputGroup2"
                            label="Note Untuk Customer"
                            label-for="lastName">
                <b-form-textarea id="content"
                              v-model.trim="$v.form.content.$model"
                              placeholder="Note ...">
                </b-form-textarea>
              </b-form-group>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
            <br/>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import axios from 'axios'
import { required, email,  numeric} from "vuelidate/lib/validators"

export default {
  name: "ValidationForms",
  data() {
    return {
      form: {
        email: "",
        status_pay: "",
        status: "",
        order_pay: "",
        buy_method: "",
        content: "",
        provider: "rpx",
        phone: '',
        address: "",
        post_code: "",
        payment_method: "",
        n_resi: "",
        shipping_method: "",
        qty: "",
        receiver: "",
      },
      checked: [
        {is: false},
        {is: false}
      ],

      productSelected: [],
      qtyProduct: [],
      province: '',
      province_id: '',
      provincies: [],
      cities: [],
      city: '',
      calculate: {
        qty: 0,
        gram_per_unit: 0,
        totalGram: 0
      },
      productPurchased: [],
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          product: [],
          qty: [],
          shipping_fee: [],
          shipping_address: [],
          shipping_method: [],
          postal_code: [],
          tracking_no: [],
          receiver: [],
          email: [],
          phone: [],
          method: [],
          status: []
        },
      },
      products: [],
      payment: "",
      payments: [],
      sold: '',
      isLoading: false
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      payment_method: {
        required
      },
      content: {
        required
      },
      order_pay: {
        numeric
      },
      status: {
        required
      },
      status_pay: {
        required
      },
      buy_method: {
        required
      },
      post_code: {
        required
      },
      address: {
        required
      },
      phone: {
        required
      },
      email: {
        required,
        email,
        isUnique (value) {
          if (value === '') return true

          return new Promise ((resolve, reject) => {
            setTimeout(() => {

              this.$http.get(`order-email/${value}`)
              .then((success) => {
                if (success.data == false) {
                  resolve(true)
                  return true
                }else{
                  reject(false)
                  return false
                }
              })
              .catch((error) => {
                if (error.response) {
                  this.errors.code = error.response.status;
                  this.errors.status = error.response.data.meta.code;
                  this.errors.headers = error.response.headers;
                }
              })
            }, 350 + Math.random() * 300)
          })
        }
      },
      shipping_method: {
        required
      },
      qty: {
        required,
        numeric
      },
      receiver: {
        required
      },
      product: {
        required
      }
    }
  },
  created() {
    this.$http.get(`product-dinar-data`).then((result) => {
      this.products = result.data
    }).catch((error) => {
      if (error.response) {
        this.errors.status = error.response.data.meta.code;
        this.errors.code = error.response.status;
        this.errors.headers = error.response.headers;
      }
    })
    this.$http.get(`get-provincies`)
    .then((response) => {
      this.provincies = response.data;
    })
  },
  methods: {
    loadPayment() {

      this.$http.get(`payment-data` ,{
        params: {
          payments: this.payment
        }
      }).then((result) => {
        this.payments = result.data.meta.data.payments
      }).catch((error) => {
        if (error.response) {
          this.errors.status = error.response.data.meta.code;
          this.errors.code = error.response.status;
          this.errors.headers = error.response.headers;
        }
      })
    },
    postOrder() {
      this.validate()
      this.isLoading =true
      if (this.productSelected.length < 0) {
        return this.$swal.fire(
          'Failed!',
          'Produk tidak boleh kosong.',
          'error'
        ).then(() => {
          this.isLoading = false
        })
      }

      if (this.form.post_code == '') {
        return this.$swal.fire(
          'Failed!',
          'Kode pos tidak boleh kosong.',
          'error'
        ).then(() => {
          this.isLoading = false
        })
      }

      if (this.province == '') {
        return this.$swal.fire(
          'Failed!',
          'Provinsi tidak boleh kosong.',
          'error'
        ).then(() => {
          this.isLoading = false
        })
      }

      if (this.city == '') {
        return this.$swal.fire(
          'Failed!',
          'Kota tidak boleh kosong.',
          'error'
        ).then(() => {
          this.isLoading = false
        })
      }
          const formData = new FormData();
          formData.append("shipping_method", this.form.shipping_method)
          formData.append("product_id", JSON.stringify(this.productSelected));
          formData.append("email", this.form.email);
          formData.append("payment_status", this.form.status_pay);
          formData.append("method", this.form.buy_method);
          formData.append("shipping_fee", this.form.order_pay);
          formData.append("shipping_address", this.form.address);
          formData.append("postal_code", this.form.post_code);
          formData.append("payment_method", this.payment);
          formData.append("status", this.form.status);
          formData.append("tracking_no", this.form.n_resi);
          formData.append("note", this.form.content);
          formData.append("receiver_name", this.form.receiver);
          formData.append("provider", this.form.provider)
          formData.append("province", this.province_id)
          formData.append("city", this.city.value)
          formData.append("phone", this.form.phone)


          this.$http.post(`order-store`, formData)
          .then(() => {
            this.isLoading = false
            this.$router.push({name : 'List Koin Nusantara Order'})
            this.$toasted.success('Koin Nusantara Order successfully created!')
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
              if(this.errors.status == 422) {
                this.$toasted.error('Please fill out the form that must be required')
                this.errors.message = error.response.data.meta.message;
                this.errors.form.product = this.errors.message.product_id;
                this.errors.form.qty = this.errors.message.qty;
                this.errors.form.shipping_fee = this.errors.message.shipping_fee;
                this.errors.form.shipping_address = this.errors.message.shipping_address;
                this.errors.form.shipping_method = this.errors.message.shipping_method;
                this.errors.form.postal_code = this.errors.message.postal_code;
                this.errors.form.tracking_no = this.errors.message.tracking_no;
                this.errors.form.email = this.errors.message.email;
                this.errors.form.receiver = this.errors.message.receiver_name;
                this.errors.form.method = this.errors.message.method;
                this.errors.form.status = this.errors.message.status;
              }else if(this.errors.status == 404) {
                this.sold = error.response.data.meta.message;
                this.$toasted.error(this.sold)
              }
            }
          })

    },
    updateQty(index, $event) {
      this.productSelected[index].qty = $event
    },
    retrieveProduct(data, $event) {
      const index = this.productSelected.findIndex(v => v == data)
      const checked = $event
      if (!checked && index >= 0) {
        this.productSelected.splice(index, 1)
      }

      if (checked && index < 0) {
        const gram_per_unit = data.gram_per_unit
        const name = data.name
        const product = data.hashed_id

        this.productSelected.push({
          product_id: product,
          name: name,
          gram_per_unit: gram_per_unit,
          qty: 0
        })
      }

    },
    doSearchProvince(province) {
      let provincies = []
      for (let index = 0; index < province.length; index++) {
        provincies.push(province[index].value)
      }
    },
    doSearchCity(city) {
      let cities = []
      for (let index = 0; index < city.length; index++) {
        cities.push(city[index].value)
      }
    },
    getCities(data) {
      if (typeof data.value !== 'undefined') {
        this.$http.get(`get-cities/` + data.value)
        .then((response) => {
          this.cities = response.data;
        })
        this.province_id = data.value
      }
    },
    getShippingRates() {
      this.isLoading = true
      if (this.productSelected.length < 0) {
        return this.$swal.fire(
          'Failed!',
          'Produk tidak boleh kosong.',
          'error'
        ).then(() => {
          this.isLoading = false
        })
      }

      if (this.form.post_code == '') {
        return this.$swal.fire(
          'Failed!',
          'Kode pos tidak boleh kosong.',
          'error'
        ).then(() => {
          this.isLoading = false
        })
      }

      for (let i = 0; i < this.productSelected.length; i++) {
        if (this.productSelected[i].qty == 0) {
          return this.$swal.fire(
            'Failed!',
            this.productSelected[i].name + ' Jumlah Keping tidak boleh 0',
            'error'
          ).then(() => {
            this.isLoading = false
          })
        }
        const lastCalculate = this.productSelected[i].gram_per_unit * this.productSelected[i].qty
        this.calculate.totalGram += lastCalculate
      }

      const formData = new FormData();
      formData.append("provider", this.form.provider);
      formData.append("postal_code", this.form.post_code);
      formData.append("weight", this.calculate.totalGram / 1000);

      axios.post(`https://service.treasury.id/api/v1/antigrvty/shipping/rates`, formData, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('access_token')}`
        }
      })
        .then((result) => {
          this.isLoading = false
          this.form.order_pay = result.data.data.fee
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
            if(this.errors.status == 422) {
              this.$toasted.error('Please fill out the form that must be required')
              this.errors.message = error.response.data.meta.message;
            }else if(this.errors.status == 404) {
              this.sold = error.response.data.meta.message;
              this.$toasted.error(this.sold)
            }
          }
        })

    },

    onReset() {
      // Reset validation
      this.form = Object.assign({},)
      this.submitted = false
      this.$nextTick(() => {
        this.$v.$reset()
        this.feedBack = 'secondary'
      })
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  },
  mounted () {
    this.loadPayment();
  },
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

.products {
  max-width: 100%;
  height: auto;
  margin: 2em;
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
